// StockList.js

// import React, { useContext } from "react";
// import { WebSocketContext } from "./WebSocketContext";

// const StockList = () => {
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   return (
//     <div>
//       <h1>Stock List</h1>
//       <p>Last Update: {lastUpdateTime?.toLocaleString()}</p>
//       <ul>
//         {stocks.map((stock, index) => (
//           <li key={index}>
//             {/* Render your stock data here */}
//             {stock.symbol}: {stock.price}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default StockList;


// import React, { useContext } from "react";
// import { WebSocketContext } from "./WebSocketContext";

// const StockList = () => {
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   return (
//     <div>
//       <h2>Stock List</h2>
//       <p>Last Update: {lastUpdateTime ? lastUpdateTime.toLocaleString() : "N/A"}</p>
//       <ul>
//         {stocks.map((stock, index) => (
//           <li key={index}>
//             {JSON.stringify(stock)}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default StockList;


// import React, { useContext } from "react";
// import { WebSocketContext } from "./WebSocketContext";

// const StockList = () => {
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   return (
//     <div>
//       <h2>Stock List</h2>
//       <p>Last Update: {lastUpdateTime ? lastUpdateTime.toLocaleString() : "N/A"}</p>
//       <ul>
//         {Object.values(stocks).map((stock) => (
//           <li key={stock.Symbol}>
//             Symbol: {stock.Symbol}, Ask: {stock.Ask}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default StockList;

import React, { useContext, useState, useEffect } from "react";
import { WebSocketContext } from "./WebSocketContext";
import StockCard from "./components/StockCard";
import "./StockList.css"; // Add CSS for the stock list
import BottomSheet from './components/BottomSheet';
import searchIcon from './assets/search.svg';
import {Search, LiveTv} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Refresh } from '@mui/icons-material';

const StockList = () => {
  const { stocks, userScripts, userDetails, lastUpdateTime, fetchUserScriptsExternal } = useContext(WebSocketContext);
  {/* <StockCard key={stock.Symbol} stock={stock} /> */}
  // const stocks = [];
  // const userScripts=[], userDetails=[], lastUpdateTime=[];
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [filter, setFilter] = useState("MCX FUT"); // Added filter state
  const navigate = useNavigate();
  const [showTv, setShowTv] = useState(false);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleToggle = () => {
    setShowTv(prevState => !prevState);
  };

  const handleCardClick = (stock) => {
    setSelectedStock(stock);
    if(stock.exchange=="OPTSTK"){
      stock.intraday = userDetails.OPTSTK_intraday;
    } else if(stock.exchange=="FUTSTK"){
      stock.intraday = userDetails.FUTSTK_intraday;
    } else if(stock.exchange=="FUTCOM"){
      stock.intraday = userDetails.FUTCOM_intraday;
    }
    setShowBottomSheet(true);
  };

  const handleCloseSheet = () => {
    setShowBottomSheet(false);
    setSelectedStock(null);
    // alert("closed"+showBottomSheet);
  };

  // Function to get the latest stock data for the selected stock
  const getLatestStockData = (stock) => {
    return stocks[stock.instrument_token] || {};
  };

  // Effect to update the selected stock data in the bottom sheet whenever stocks context updates
  useEffect(() => {
    if (selectedStock) {
      const latestStockData = getLatestStockData(selectedStock);
      setSelectedStock((prevStock) => ({
        ...prevStock,
        live: latestStockData,
      }));
    }
  }, [stocks, selectedStock]);

  // Filtered user scripts based on the selected filter
  const filteredUserScripts = Object.values(userScripts).filter((stock) => {
    if (filter === "MCX FUT") {
      return stock.exchange === "FUTCOM";
    } else if (filter === "NSE FUT") {
      return stock.exchange === "FUTSTK";
    } else if (filter === "NSE OPT") {
      return stock.exchange === "OPTSTK";
    }
    return true;
  });

  const handleRefresh = () => {
    fetchUserScriptsExternal();
    //alert("Refresh");
    //refreshTradesAndPortfolio("trades");
  };

  return (
    <div>

        {showTv && <div class="video-container">
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/4QvoTo1CFPs?si=A2GdO2Akv5IumonS"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
        </div>}
    <div className="stock-page">
      
      {userScripts.length == 0 && <div style={{ textAlign:'center', marginTop:'100px' }}>
        Your watchlist is currently empty.<br/>Please add your desired scripts.
        <button className="search-icon_2" onClick={handleNavigation('/addScript')}>Add Script</button>
      </div>}

      <button className="refresh-button" onClick={handleRefresh}><Refresh style={{ height:'22px', color:'white' }} /></button>
      <button className="search-icon" onClick={handleNavigation('/addScript')}><Search style={{ height:'22px', color:'white' }} /></button>
      <button className="tv-icon" onClick={handleToggle}><LiveTv style={{ height:'22px', color:'white' }} /></button>
      {/* <img src={Search} onClick={handleNavigation('/addScript')} className="search-icon"></img> */}

      {userScripts.length > 0 && <div style={{ textAlign:'center', margin:'0px auto 15px'}}>
          <div className="toggle-switch" style={{ width:'257px'}}>
            <button  style={{ width:'auto' }} className={filter=="MCX FUT" ? 'active' : ''} onClick={() => setFilter("MCX FUT")}>MCX FUT</button>
            <button  style={{ width:'auto' }} className={filter=="NSE FUT" ? 'active' : ''} onClick={() => setFilter("NSE FUT")}>NSE FUT</button>
            <button  style={{ width:'auto' }} className={filter=="NSE OPT" ? 'active' : ''} onClick={() => setFilter("NSE OPT")}>NSE OPT</button>
            {/* <span className={!isLimitOrder ? 'active' : ''} onClick={handleOrderTypeToggle}>Market</span>
            <span className={isLimitOrder ? 'active' : ''} onClick={handleOrderTypeToggle}>Limit</span> */}
          </div>
      </div>}

      {/* { lastUpdateTime && <p className="lastUpdate">Updated @ {lastUpdateTime ? lastUpdateTime.toLocaleString() : "N/A"}</p>} */}

      {/* {
        Object.values(userScripts).map((stock) => {
          const _stock = stocks[stock.instrument_token] || {};
          return (
            <StockCard 
              key={stock.instrumentIdentifier} 
              _stock={_stock} 
              stock={stock}
              onCardClick={handleCardClick} />
          );
        })
      } */}

      {filteredUserScripts.map((stock) => {
        const _stock = stocks[stock.instrument_token] || {};
        return (
          <StockCard 
            key={stock.instrumentIdentifier} 
            _stock={_stock} 
            stock={stock}
            onCardClick={handleCardClick} />
        );
      })}

      {/* {Object.values(userScripts).map((stock) => (
          
          <StockCard key={stock.instument_token} stock={stock} />
      ))} */}
      {selectedStock && <BottomSheet isOpen={showBottomSheet} onClose={handleCloseSheet} userDetails={userDetails} stock={selectedStock} />}
    </div>
    </div>
    
  );
};

export default StockList;
